import React, { useState } from 'react'
import styled, { ThemeProvider } from 'styled-components'
import SEO from './Header/SEO'

import Navbar from './Header/Navbar'
import Footer from './Footer/Footer'

import './all.scss'
import GlobalStyles from '../styles/global'

const GlobalLayout = ({
  title,
  excerpt,
  themeOption,
  categoryColors,
  children,
  imageUrl
}) => {

  const [contactModalOpen, setContactModalOpen] = useState(false)
  const [socialModalOpen, setSocialModalOpen] = useState(false)

  let themeColors = { highlight: 'transparent', text: '#ffffff' }
  const termId =
    themeOption && themeOption.options && themeOption.options.theme
      ? themeOption.options.theme.termId
      : null

  if (termId) {
    const theme = categoryColors.edges.find(edge => {
      return edge.node.wordpress_id === termId
    })
    themeColors = () => {
      return {
        highlight:
          theme.node.acf && theme.node.acf.theme_color
            ? theme.node.acf.theme_color
            : 'transparent',
        text:
          theme.node.acf && theme.node.acf.theme_text_color
            ? theme.node.acf.theme_text_color
            : '#ffffff',
      }
    }
  }

  const handleContactModal = () => {
    document.querySelector('html').classList.toggle('is-clipped')
    setContactModalOpen(!contactModalOpen)
  }

  const handleSocialModal = () => {
    document.querySelector('html').classList.toggle('is-clipped')
    setSocialModalOpen(!socialModalOpen)
  }

  const ThemedBackground = styled.div`
    background-color: ${props => props.theme.highlight};
    color: ${props => props.theme.text};
  `

  return (
    <ThemeProvider theme={themeColors}>
      <ThemedBackground>
        <SEO title={title} imageUrl={imageUrl} excerpt={excerpt} />
        <GlobalStyles />
        <Navbar
          contactModalHandler={handleContactModal}
          socialModalHandler={handleSocialModal}
        />
        <main>{children}</main>
        <Footer
          contactModalOpen={contactModalOpen}
          contactModalHandler={handleContactModal}
          socialModalOpen={socialModalOpen}
          socialModalHandler={handleSocialModal}
        />
      </ThemedBackground>
    </ThemeProvider>
  )
}

export default GlobalLayout
