import React from 'react'
import {
  GoogleMap,
  Marker,
  withGoogleMap,
  withScriptjs,
} from 'react-google-maps'
import mapOptions from './GoogleMapOptions'

const Map = withScriptjs(
  withGoogleMap(({ googleMapAcf, isMarkerShown }) => {
    const lat = parseFloat(googleMapAcf.lat)
    const lng = parseFloat(googleMapAcf.lng)
    return (
      <GoogleMap
        defaultZoom={17}
        defaultCenter={{ lat, lng }}
        defaultOptions={{ styles: mapOptions.styles }}
      >
        {isMarkerShown && <Marker position={{ lat, lng }} />}
      </GoogleMap>
    )
  })
)

export default Map
