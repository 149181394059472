import React from 'react'
import styled from 'styled-components'

const HamburgerWrapper = styled.div`
  position: absolute;
  top: 1rem;
  right: 1rem;

  button {
    border: none !important;
    background: transparent !important;
    outline: none !important;
  }
`

const Hamburger = ({ isActive, hamburgerClickHandler }) => {
  return (
    <HamburgerWrapper>
      <button
        type="button"
        className={`navbar-burger ${isActive ? 'is-active' : ''}`}
        aria-label="menu"
        aria-expanded="false"
        onClick={hamburgerClickHandler}
      >
        <span aria-hidden="true" />
        <span aria-hidden="true" />
        <span aria-hidden="true" />
      </button>
    </HamburgerWrapper>
  )
}

export default Hamburger
