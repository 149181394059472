import React, { useState } from 'react'
import { Link, StaticQuery, graphql, navigate } from 'gatsby'
import styled from 'styled-components'

import OfeIcon from '../../svg/OfeIcon'
import Hamburger from './Hamburger'

const NavbarBrand = styled.div`
  svg {
    width: 4rem;

    path {
      fill: ${props => props.theme.text};
    }
  }
`

const NavbarMenu = styled.div`
  button {
    font-family: inherit;
    font-size: inherit;
    font-weight: 300;
    background: transparent;
    border: none;
    cursor: pointer;

    &:hover {
      color: hsla(0, 0%, 100%, 0.7);
    }

    &:focus {
      outline: none;
    }
  }
`

const Navbar = ({ contactModalHandler, socialModalHandler }) => {
  const [isActive, setIsActive] = useState(false)

  const hamburgerClickHandler = () => {
    setIsActive(!isActive)
  }

  return (
    <StaticQuery
      query={graphql`
        {
          allWordpressMenusMenusItems(filter: { slug: { eq: "header" } }) {
            edges {
              node {
                items {
                  target
                  url
                  classes
                  menu_order
                  attr_title
                  title
                  wordpress_id
                  xfn
                }
              }
            }
          }
        }
      `}
      render={data => (
        <nav
          className={`navbar is-transparent is-fixed-top ${
            isActive ? 'is-open' : ''
          }`}
          role="navigation"
          aria-label="main navigation"
        >
          <NavbarBrand className="navbar-brand">
            <Link to="/">
              <span className="is-sr-only">Our Friends Electric</span>
              <OfeIcon />
            </Link>
            <Hamburger
              isActive={isActive}
              hamburgerClickHandler={hamburgerClickHandler}
            />
          </NavbarBrand>
          <NavbarMenu className={`navbar-menu ${isActive ? 'is-active' : ''}`}>
            <div className="navbar-start" />
            <div className="navbar-end">
              {data.allWordpressMenusMenusItems.edges[0].node.items.map(
                item => {
                  // TODO:: Allow for nested menu items by looking at children
                  let { url } = item
                  const externalLink = item.target
                  if (!externalLink) {
                    url = item.url.replace(
                      'https://admin.ourfriendselectric.com.au',
                      ''
                    )
                    if (url.includes('#contact')) {
                      return (
                        <button
                          type="button"
                          className="navbar-item"
                          key={item.title}
                          onClick={contactModalHandler}
                        >
                          {item.title}
                        </button>
                      )
                    }
                    if (url.includes('#follow-us')) {
                      return (
                        <button
                          type="button"
                          className="navbar-item"
                          key={item.title}
                          onClick={socialModalHandler}
                        >
                          {item.title}
                        </button>
                      )
                    }
                    if (url.includes('#')) {
                      //* Handle in-page anchor links with navigate()
                      return (
                        <button
                          type="button"
                          className="navbar-item"
                          onClick={() => {
                            location.pathname === '/'
                              ? document
                                  .getElementById(url.replace(/\/#/, ''))
                                  .scrollIntoView({ behavior: 'smooth' })
                              : navigate(url)
                            hamburgerClickHandler()
                          }}
                          key={item.title}
                        >
                          {item.title}
                        </button>
                      )
                    }

                    return (
                      <Link
                        className="navbar-item"
                        to={url}
                        key={item.title}
                        activeStyle={{ color: '#ccc' }}
                      >
                        {item.title}
                      </Link>
                    )
                  }
                  return (
                    <a
                      className="navbar-item"
                      rel="noopener noreferrer"
                      to={url}
                      key={item.title}
                      target={externalLink}
                    >
                      {item.title}
                    </a>
                  )
                }
              )}
            </div>
          </NavbarMenu>
        </nav>
      )}
    />
  )
}

export default Navbar
