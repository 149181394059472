import React from 'react'
import styled from 'styled-components'
import OfeFacebook from '../../svg/OfeFacebook'
import OfeInstagram from '../../svg/OfeInstagram'

const SocialLink = ({ options, type }) => {
  let src
  let fillColor

  switch (type) {
    case 'facebook':
      src = <OfeFacebook />
      fillColor = 'rgba(255, 255, 255, 0.7)'
      break

    case 'instagram':
      src = <OfeInstagram />
      fillColor = 'rgba(255, 255, 255, 0.7)'
      break

    default:
      break
  }

  const SocialIcon = styled(props => <a {...props} />)`
    svg {
      max-width: 80px;
    }

    path {
      transition: fill 0.4s ease;
    }

    &:hover path {
      fill: ${fillColor};
    }
  `

  return (
    <SocialIcon rel="noopener" target={options.target} href={options.url}>
      {src}
      <span className="is-sr-only">{options.title}</span>
    </SocialIcon>
  )
}

export default SocialLink
