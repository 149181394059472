import { createGlobalStyle } from 'styled-components'

const GlobalStyles = createGlobalStyle`
  @font-face {
    font-family: GothamPro;
    src: url(/fonts/gotham-pro/GothamPro-Light.eot);
    src: url(/fonts/gotham-pro/GothamPro-Light.woff2) format("woff2"), url(/fonts/gotham-pro/GothamPro-Light.woff) format("woff"), url(/fonts/gotham-pro/GothamPro-Light.ttf) format("truetype"), url(/fonts/gotham-pro/GothamPro-Light.svg#GothamPro-Light) format("svg"), url(/fonts/gotham-pro/GothamPro-Light.eot?#iefix) format("embedded-opentype");
    font-weight: 100;
    font-style: normal;
    font-display: fallback;
  }

  @font-face {
    font-family: GothamPro;
    src: url(/fonts/gotham-pro/GothamPro-LightItalic.eot);
    src: url(/fonts/gotham-pro/GothamPro-LightItalic.woff2) format("woff2"), url(/fonts/gotham-pro/GothamPro-LightItalic.woff) format("woff"), url(/fonts/gotham-pro/GothamPro-LightItalic.ttf) format("truetype"), url(/fonts/gotham-pro/GothamPro-LightItalic.svg#GothamPro-LightItalic) format("svg"), url(/fonts/gotham-pro/GothamPro-LightItalic.eot?#iefix) format("embedded-opentype");
    font-weight: 100;
    font-style: italic;
    font-display: fallback;
  }

  @font-face {
    font-family: GothamPro;
    src: url(/fonts/gotham-pro/GothamPro.eot);
    src: url(/fonts/gotham-pro/GothamPro.woff2) format("woff2"), url(/fonts/gotham-pro/GothamPro.woff) format("woff"), url(/fonts/gotham-pro/GothamPro.ttf) format("truetype"), url(/fonts/gotham-pro/GothamPro.svg#GothamPro) format("svg"), url(/fonts/gotham-pro/GothamPro.eot?#iefix) format("embedded-opentype");
    font-weight: 200;
    font-style: normal;
    font-display: fallback;
  }

  @font-face {
    font-family: GothamPro;
    src: url(/fonts/gotham-pro/GothamPro-Italic.eot);
    src: url(/fonts/gotham-pro/GothamPro-Italic.woff2) format("woff2"), url(/fonts/gotham-pro/GothamPro-Italic.woff) format("woff"), url(/fonts/gotham-pro/GothamPro-Italic.ttf) format("truetype"), url(/fonts/gotham-pro/GothamPro-Italic.svg#GothamPro-Italic) format("svg"), url(/fonts/gotham-pro/GothamPro-Italic.eot?#iefix) format("embedded-opentype");
    font-weight: 200;
    font-style: italic;
    font-display: fallback;
  }

  @font-face {
    font-family: GothamPro;
    src: url(/fonts/gotham-pro/GothamPro-Medium.eot);
    src: url(/fonts/gotham-pro/GothamPro-Medium.woff2) format("woff2"), url(/fonts/gotham-pro/GothamPro-Medium.woff) format("woff"), url(/fonts/gotham-pro/GothamPro-Medium.ttf) format("truetype"), url(/fonts/gotham-pro/GothamPro-Medium.svg#GothamPro-Medium) format("svg"), url(/fonts/gotham-pro/GothamPro-Medium.eot?#iefix) format("embedded-opentype");
    font-weight: 400;
    font-style: normal;
    font-display: fallback;
  }

  @font-face {
    font-family: GothamPro;
    src: url(/fonts/gotham-pro/GothamPro-MediumItalic.eot);
    src: url(/fonts/gotham-pro/GothamPro-MediumItalic.woff2) format("woff2"), url(/fonts/gotham-pro/GothamPro-MediumItalic.woff) format("woff"), url(/fonts/gotham-pro/GothamPro-MediumItalic.ttf) format("truetype"), url(/fonts/gotham-pro/GothamPro-MediumItalic.svg#GothamPro-MediumItalic) format("svg"), url(/fonts/gotham-pro/GothamPro-MediumItalic.eot?#iefix) format("embedded-opentype");
    font-weight: 400;
    font-style: italic;
    font-display: fallback;
  }

  @font-face {
    font-family: GothamPro;
    src: url(/fonts/gotham-pro/GothamPro-Bold.eot);
    src: url(/fonts/gotham-pro/GothamPro-Bold.woff2) format("woff2"), url(/fonts/gotham-pro/GothamPro-Bold.woff) format("woff"), url(/fonts/gotham-pro/GothamPro-Bold.ttf) format("truetype"), url(/fonts/gotham-pro/GothamPro-Bold.svg#GothamPro-Bold) format("svg"), url(/fonts/gotham-pro/GothamPro-Bold.eot?#iefix) format("embedded-opentype");
    font-weight: 600;
    font-style: normal;
    font-display: fallback;
  }

  @font-face {
    font-family: GothamPro;
    src: url(/fonts/gotham-pro/GothamPro-BoldItalic.eot);
    src: url(/fonts/gotham-pro/GothamPro-BoldItalic.woff2) format("woff2"), url(/fonts/gotham-pro/GothamPro-BoldItalic.woff) format("woff"), url(/fonts/gotham-pro/GothamPro-BoldItalic.ttf) format("truetype"), url(/fonts/gotham-pro/GothamPro-BoldItalic.svg#GothamPro-BoldItalic) format("svg"), url(/fonts/gotham-pro/GothamPro-BoldItalic.eot?#iefix) format("embedded-opentype");
    font-weight: 600;
    font-style: italic;
    font-display: fallback;
  }

  @font-face {
    font-family: GothamPro;
    src: url(/fonts/gotham-pro/GothamPro-Black.eot);
    src: url(/fonts/gotham-pro/GothamPro-Black.woff2) format("woff2"), url(/fonts/gotham-pro/GothamPro-Black.woff) format("woff"), url(/fonts/gotham-pro/GothamPro-Black.ttf) format("truetype"), url(/fonts/gotham-pro/GothamPro-Black.svg#GothamPro-Black) format("svg"), url(/fonts/gotham-pro/GothamPro-Black.eot?#iefix) format("embedded-opentype");
    font-weight: 800;
    font-style: normal;
    font-display: fallback;
  }

  @font-face {
    font-family: GothamPro;
    src: url(/fonts/gotham-pro/GothamPro-BlackItalic.eot);
    src: url(/fonts/gotham-pro/GothamPro-BlackItalic.woff2) format("woff2"), url(/fonts/gotham-pro/GothamPro-BlackItalic.woff) format("woff"), url(/fonts/gotham-pro/GothamPro-BlackItalic.ttf) format("truetype"), url(/fonts/gotham-pro/GothamPro-BlackItalic.svg#GothamPro-BlackItalic) format("svg"), url(/fonts/gotham-pro/GothamPro-BlackItalic.eot?#iefix) format("embedded-opentype");
    font-weight: 800;
    font-style: italic;
    font-display: fallback;
  }

  * {
    font-family: GothamPro, serif;
  }

  body {
    background: black;
    color: white;
    font-size: 1.1em !important;
  }

  #gatsby-focus-wrapper {
    overflow: hidden;
  }

  ul {
    columns: 2 !important;
    list-style-type: disc !important;
    list-style-position: outside !important;
    padding-left: 1rem !important;
  }

  .content .taglist {
    list-style: none;
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 1.5rem;
    margin-top: 1.5rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: left;
    align-items: center;

    li {
      padding: 0 2rem 1rem 0;
      margin-bottom: 1.5rem;
      margin-top: 0;
    }
  }

  .navbar {
    background: linear-gradient(rgba(0, 0, 0, 0.7), transparent) !important;
  }

  @media screen and (max-width: 767px) {
    .navbar {
      &.is-open {
        background:
          linear-gradient(
            to bottom,
            rgba(0, 0, 0, 1),
            rgba(0, 0, 0, 0.7) 100%
          ) !important;
        height: 100vh;
      }

      .navbar-end {
        display: flex;
        justify-content: center;
        flex-flow: column;
        align-items: center;

        a.navbar-item {
          padding: 1rem 0;
          width: 100%;
          text-align: center;
        }
      }
    }
  }

  .pagination .navbar {
    justify-content: center;
  }

  .full-width-image-container {
    width: 100vw;
    height: 400px;
    position: relative;
    left: 50%;
    right: 50%;
    margin: 5em -50vw;
    background-size: cover;
    background-position: bottom;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .margin-top-0 {
    margin-top: 0 !important;
  }

  .is-two-fifths .gatsby-image-wrapper {
    height: 100%;
  }

  section {
    position: relative;
  }

  section > span {
    position: absolute;
    top: -75px;
    height: 0;
  }

  .slider-slide .gatsby-image-wrapper {
    height: 100vh;
  }
`

export default GlobalStyles
