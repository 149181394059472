import React from 'react'

const SvgOfeInstagram = props => (
  <svg
    id="OFE_INSTAGRAM_svg__Layer_1"
    x={0}
    y={0}
    viewBox="0 0 1080 1080"
    xmlSpace="preserve"
    {...props}
  >
    <style>{'.OFE_INSTAGRAM_svg__st0{fill:#fff}'}</style>
    <path
      className="OFE_INSTAGRAM_svg__st0"
      d="M540 305.82c76.28 0 85.31.3 115.47 1.65 27.86 1.25 42.98 5.94 53.07 9.83 13.33 5.19 22.86 11.38 32.85 21.37 9.98 9.98 16.17 19.52 21.37 32.85 3.89 10.08 8.54 25.21 9.83 53.07 1.4 30.15 1.65 39.19 1.65 115.47s-.3 85.31-1.65 115.47c-1.25 27.86-5.89 42.98-9.83 53.07-5.19 13.33-11.38 22.86-21.37 32.85s-19.52 16.17-32.85 21.37c-10.08 3.89-25.21 8.54-53.07 9.83-30.15 1.35-39.19 1.65-115.47 1.65s-85.31-.3-115.47-1.65c-27.86-1.3-42.98-5.94-53.07-9.83-13.33-5.19-22.86-11.38-32.85-21.37-9.98-9.98-16.17-19.52-21.37-32.85-3.89-10.08-8.54-25.21-9.83-53.07-1.35-30.15-1.65-39.19-1.65-115.47s.3-85.31 1.65-115.47c1.3-27.86 5.94-42.98 9.83-53.07 5.19-13.33 11.38-22.86 21.37-32.85 9.98-9.98 19.52-16.17 32.85-21.37 10.08-3.89 25.21-8.54 53.07-9.83 30.11-1.35 39.19-1.65 115.47-1.65m0-51.47c-77.58 0-87.31.35-117.81 1.7-30.4 1.4-51.17 6.24-69.34 13.28-18.82 7.29-34.74 17.07-50.62 32.95-15.87 15.87-25.66 31.8-32.95 50.62-7.09 18.17-11.93 38.94-13.28 69.34-1.4 30.45-1.7 40.19-1.7 117.76 0 77.58.3 87.31 1.7 117.81 1.4 30.4 6.24 51.17 13.28 69.34 7.29 18.77 17.07 34.69 32.95 50.57 15.87 15.87 31.8 25.66 50.62 32.95 18.17 7.04 38.94 11.88 69.34 13.28 30.5 1.4 40.19 1.7 117.81 1.7 77.58 0 87.31-.3 117.81-1.7 30.4-1.4 51.17-6.24 69.34-13.28 18.82-7.29 34.74-17.07 50.62-32.95 15.87-15.87 25.66-31.8 32.95-50.57 7.09-18.17 11.93-38.94 13.28-69.34 1.4-30.45 1.7-40.19 1.7-117.81 0-77.58-.3-87.31-1.7-117.76-1.4-30.4-6.24-51.17-13.28-69.34-7.29-18.82-17.07-34.74-32.95-50.62-15.87-15.87-31.8-25.66-50.62-32.95-18.17-7.09-38.94-11.88-69.34-13.28-30.5-1.35-40.23-1.7-117.81-1.7"
    />
    <path
      className="OFE_INSTAGRAM_svg__st0"
      d="M540 393.33c-81.02 0-146.72 65.7-146.72 146.72S458.98 686.77 540 686.77s146.72-65.7 146.72-146.72S621.02 393.33 540 393.33m0 241.97c-52.62 0-95.25-42.63-95.25-95.2 0-52.62 42.63-95.25 95.25-95.25s95.25 42.63 95.25 95.25c-.05 52.57-42.68 95.2-95.25 95.2m186.75-247.76c0 18.92-15.33 34.3-34.3 34.3-18.92 0-34.3-15.33-34.3-34.3 0-18.92 15.33-34.3 34.3-34.3 18.98.01 34.3 15.38 34.3 34.3"
    />
  </svg>
)

export default SvgOfeInstagram
