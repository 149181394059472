import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import styled from 'styled-components'
import GravityFormForm from 'gatsby-gravityforms-component'
import Map from '../GoogleMap'

const ModalWrapper = styled.div`
  @media screen and (max-width: 767px) {
    height: 100% !important;
    max-height: unset;
    padding-top: 40px;
  }
`

const StyledForm = styled.div`
  form {
    display: flex;
    flex-direction: column;
    padding: 2rem;
  }

  .gform_body .gform_fields {
    display: flex;
    flex-flow: column;
    padding-left: 0 !important;
  }

  .ginput_container {
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }

  .ginput_container > input {
    width: 100%;
  }

  .gravityform__field {
    margin: 0.4rem 0;
    display: flex;
    align-items: flex-end;
    flex-flow: column;
    width: 100%;
  }

  .gravityform__field > label {
    flex: 1;
    text-align: center;
    padding: 0.5rem 0.2rem 0.2rem;
    font-weight: 100;
  }

  .gravityform__field input,
  .gravityform__field textarea {
    flex: 3;
    min-width: 80%;
    max-width: 80%;
    border: none;
    font-size: 1.3rem;
    padding: 0.5rem;
    background: rgba(255, 255, 255, 0.7);
  }

  .gravityform__field textarea {
    min-height: 10rem;
  }

  button.gravityform__button {
    font-size: 1.2rem;
    padding: 0.5rem 3rem;
    margin-top: 2.6rem;
    transition: background 0.4s;
    background: rgba(255, 255, 255, 0.9);
    border: none;
    box-shadow: none;
    color: black;
    width: 100%;
    align-self: flex-end;

    &:hover {
      background: rgba(255, 255, 255, 0.4);
    }
  }

  .gravityform__error_inform,
  .gravityform__error_message {
    text-align: right;
    width: 100%;
    color: red;
    font-size: 0.9rem;
  }

  span.gravityform__button__loading_span {
    font-size: 0.5rem;
    display: block;
  }

  #gform_confirmation_message_1 {
    text-align: center;
    font-size: 2rem;
    max-width: 500px;
    margin: 0 auto;
    padding: 3rem;
  }

  .gform_footer {
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }

  .gform_footer button {
    width: 80%;
  }

  @media screen and (max-width: 767px) {
    form {
      padding: 0 2rem;
      margin-bottom: 1rem;
    }

    .gravityform__field > input,
    .gravityform__field > textarea {
      max-width: 100%;
    }

    button.gravityform__button,
    .gravityform__field > input,
    .gravityform__field > textarea {
      min-width: 100%;
    }
  }
`

const MapWrapper = styled.div`
  position: relative;
  height: 100%;

  @media screen and (max-width: 767px) {
    min-height: 400px;
    height: 400px;
    margin: 2rem;
  }
`

const ContactModal = ({ isOpen, closeModal }) => {
  const data = useStaticQuery(
    graphql`
      query {
        allGfForm {
          edges {
            node {
              ...GravityFormComponent
            }
          }
        }
        wordpressAcfOptions {
          options {
            google_map {
              address
              lat
              lng
            }
          }
        }
      }
    `
  )

  const { allGfForm, wordpressAcfOptions } = data

  const googleMap =
    wordpressAcfOptions &&
      wordpressAcfOptions.options &&
      wordpressAcfOptions.options.google_map
      ? wordpressAcfOptions.options.google_map
      : false

  return (
    <ModalWrapper className={`modal ${isOpen ? 'is-active is-clipped' : ''}`}>
      <div className="modal-background" />
      <div className="modal-content">
        <div className="modal-body">
          <div className="container is-fluid is-marginless">
            <div className="columns is-marginless">
              <div className="column is-paddingless">
                {/* TODO:: Submit to an endpoint and process the GF Submission */}
                <StyledForm>
                  <GravityFormForm
                    id={1}
                    formData={allGfForm}
                    lambda="/.netlify/functions/contact-submit"
                  />
                </StyledForm>
              </div>
              <div className="column is-paddingless">
                <MapWrapper>
                  <Map
                    className="map"
                    googleMapAcf={googleMap}
                    isMarkerShown
                    loadingElement={<div style={{ height: `100%` }} />}
                    containerElement={<div style={{ height: `100%` }} />}
                    mapElement={<div style={{ height: `100%` }} />}
                    googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${process.env.GATSBY_GOOGLE_MAPS_API_KEY}&v=3.exp&libraries=geometry,drawing,places`}
                  />
                </MapWrapper>
              </div>
              <button
                className="modal-close is-large"
                aria-label="close"
                onClick={closeModal}
                type="button"
              />
            </div>
          </div>
        </div>
      </div>
    </ModalWrapper>
  )
}

export default ContactModal
