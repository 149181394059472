import React from 'react'

const SvgOfeIcon = props => (
  <svg viewBox="0 0 1080 1080" {...props}>
    <path
      d="M279.3 862.26l212.41-267.08-112.43-89.2 412.37-287.63-212.42 267.04 112.43 89.1L279.3 862.26z"
      fill="#fff"
    />
  </svg>
)

export default SvgOfeIcon
