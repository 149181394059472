import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import SocialLink from '../Content/SocialLink'

const SocialModal = ({ isOpen, closeModal }) => {
  const { allWordpressAcfOptions } = useStaticQuery(
    graphql`
      query {
        allWordpressAcfOptions {
          edges {
            node {
              options {
                contact_us
                find_us
                follow_us {
                  facebook {
                    target
                    title
                    url
                  }
                  instagram {
                    target
                    title
                    url
                  }
                }
              }
            }
          }
        }
      }
    `
  )

  const { options } = allWordpressAcfOptions.edges[0].node

  return (
    <div className={`modal ${isOpen ? 'is-active is-clipped' : ''}`}>
      <div className="modal-background" />
      <div className="modal-content">
        <div className="container">
          <div className="columns is-marginless">
            <div className="column has-text-centered">
              <SocialLink
                type="facebook"
                options={options.follow_us.facebook}
              />
              <SocialLink
                type="instagram"
                options={options.follow_us.instagram}
              />
            </div>
          </div>
        </div>
      </div>
      <button
        type="button"
        className="modal-close is-large"
        aria-label="close"
        onClick={closeModal}
      />
    </div>
  )
}

export default SocialModal
