/**
 * SEO component
 */

import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'
import { SITE_TITLE, KEYWORDS } from '../../constants'

const SEO = ({ title, imageUrl, excerpt, lang }) => {


  const data = useStaticQuery(
    graphql`
    query {
      wordpressSiteMetadata {
        description
        name
      }
    }
    `
  )

  const strippedExcerpt = excerpt && excerpt.replace(/(<([^>]+)>)/ig, "")

  const { name, description, url } = data.wordpressSiteMetadata

  const ogDescription = strippedExcerpt || description

  const meta = [
    { property: `og:title`, content: `${title} | ${name}`, },
    { property: `og:description`, content: ogDescription, },
    { property: `og:type`, content: `website`, },
    { property: `og:url`, content: url, },
    { property: `og:image`, content: imageUrl, },
    { name: `twitter:card`, content: `summary`, },
    { name: `twitter:creator`, content: name, },
    { name: `twitter:title`, content: `${title} | ${name}`, },
    { name: `twitter:description`, content: ogDescription, },
    { name: `description`, content: ogDescription, },
    { name: `keywords`, content: KEYWORDS },
    { name: "robots", content: "index,follow" }
  ];

  return (
    <Helmet
      title={title}
      titleTemplate={`%s | ${name}`}
      htmlAttributes={{ lang }}
      meta={meta}
    />
  )
}

SEO.defaultProps = {
  lang: `en-AU`,
}

SEO.propTypes = {
  title: PropTypes.string.isRequired,
  lang: PropTypes.string,
}

export default SEO
