import React from 'react'

const SvgOfeFacebook = props => (
  <svg viewBox="0 0 1080 1080" {...props}>
    <path
      d="M828.07 541.74c0-159.09-128.99-288.07-288.07-288.07-159.09 0-288.07 128.98-288.07 288.07 0 143.87 105.45 263.05 243.26 284.59V624.96h-73.62v-83.23h73.62l.05-63.46c0-72.19 42.77-112.23 108.56-112.23 31.51 0 64.24 6.05 64.24 6.05v70.41h-36.08c-35.79 0-47.22 22.39-47.22 45.2l.07 54.02h80.02l-12.79 83.23h-67.23v201.37c137.81-21.53 243.26-140.72 243.26-284.58"
      fill="#fff"
    />
  </svg>
)

export default SvgOfeFacebook
