import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import SocialLink from '../Content/SocialLink'
import ContactModal from '../Modal/ContactModal'
import SocialModal from '../Modal/SocialModal'

const FooterContent = styled.div`
  h2 {
    text-transform: uppercase;
    font-weight: 100 !important;
    margin-bottom: 3rem;
  }
`

const Footer = ({
  contactModalOpen,
  contactModalHandler,
  socialModalOpen,
  socialModalHandler,
}) => (
  <StaticQuery
    query={graphql`
      {
        allWordpressAcfOptions {
          edges {
            node {
              options {
                contact_us
                find_us
                follow_us {
                  facebook {
                    target
                    title
                    url
                  }
                  instagram {
                    target
                    title
                    url
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={data => {
      const {
        contact_us,
        find_us,
        follow_us,
      } = data.allWordpressAcfOptions.edges[0].node.options
      return (
        <>
          <footer className="footer">
            <FooterContent className="container">
              <div className="columns">
                <div className="column">
                  <h2 className="is-size-3 is-size-2-desktop">Contact Us</h2>
                  <div dangerouslySetInnerHTML={{ __html: contact_us }} />
                </div>
                <div className="column">
                  <h2 className="is-size-3 is-size-2-desktop">Find Us</h2>
                  <div dangerouslySetInnerHTML={{ __html: find_us }} />
                </div>
                <div className="column">
                  <h2 className="is-size-3 is-size-2-desktop">Follow Us</h2>
                  <SocialLink type="facebook" options={follow_us.facebook} />
                  <SocialLink type="instagram" options={follow_us.instagram} />
                </div>
              </div>
            </FooterContent>
          </footer>
          <ContactModal
            isOpen={contactModalOpen}
            closeModal={contactModalHandler}
          />
          <SocialModal
            followUs={follow_us}
            isOpen={socialModalOpen}
            closeModal={socialModalHandler}
          />
        </>
      )
    }}
  />
)

export default Footer
